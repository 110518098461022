import { cn } from '@/lib/utils';
import { cloneElement, isValidElement } from 'react';

export function toNonInteractiveElement(
  el: React.ReactNode,
  { className, ...props }: Record<string, any> = {},
) {
  return isValidElement(el)
    ? cloneElement(el, {
        ...props,
        tabIndex: -1,
        focusable: false,
        'aria-hidden': true,
        className: cn(el.props.className, className),
      } as Record<string, any>)
    : null;
}
