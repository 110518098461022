import Color from 'color';
import _kebabCase from 'lodash/kebabCase';
import plugin from 'tailwindcss/plugin';
import { PluginCreator } from 'tailwindcss/types/config';
import {
  type ThemeMode,
  type Token,
  type TokenGroup,
  type TokenMap,
  type TokenName,
  fonts,
  themeModes,
  tokensMap,
  groupedTokensMap,
} from '../tokens';

type Config = {
  variants: {
    name: string;
    definition: string[];
  }[];
  utilities: Record<string, Record<string, any>>;
  components: Record<string, Record<string, any>>;
  fonts: Partial<Record<'@font-face', Record<string, any>[]>>;
  vars: Partial<Record<TokenGroup, Record<string, string>>>;
};

type ExpandedTokenMap = {
  [K in TokenName]: TokenMap[K] & { themeMode?: string };
};

const defaultThemeMode: ThemeMode = 'light-plain';
const baseFontSize = 16;

let cachedParsedColors: Record<string, number[]> = {};
let cachedThemeSelectors: Record<string, string> = {};

export function customTailwindPlugin() {
  const config = getResolvedConfig();

  function injector({
    addBase,
    addUtilities,
    addComponents,
    addVariant,
    theme
  }: Parameters<PluginCreator>[0]) {
    // consider adding base (addBase)
    addBase(config.fonts as any);

    // add the css variables to "@layer utilities"
    addUtilities(config.utilities);

    addComponents(config.components);

    // add variants e.g. [variant]:text-xl
    config.variants.forEach((variant) => {
      addVariant(variant.name, variant.definition);
    });
  }

  return plugin(injector, {

    theme: {
      extend: {
        colors: {
          ...config.vars.color,
        },
        spacing: {
          ...config.vars.size,
        },
        borderRadius: {
          ...config.vars.radius,
        },
        padding: {
          ...config.vars.padding,
          ...config.vars.size,
        },
        gap: {
          ...config.vars.size,
        },
        fontFamily: {
          ...config.vars.fontFamily,
        },
        fontSize: {
          ...config.vars.fontSize,
        },
        lineHeight: {
          ...config.vars.lineHeight,
        },
        letterSpacing: {
          ...config.vars.letterSpacing,
        },
      },
    },
  });
}

export function getResolvedConfig() {
  const config: Config = {
    utilities: {},
    variants: [],
    components: {},
    fonts: {},
    vars: {},
  };

  injectFonts(config);
  injectRootSelectorsIntoUtilities(config);
  injectThemeBasedVariantDefinitions(config);
  injectTokensAndDefineVars(config);
  injectTypographyComponents(config);

  // a side-effect, cleanup
  cachedParsedColors = {};
  cachedThemeSelectors = {};

  return config;
}

function injectFonts(config: Config) {
  if (fonts.length === 0) return;

  config.fonts['@font-face'] = fonts.map((font) => {
    return {
      fontFamily: font.family,
      src: font.src,
      fontWeight: font.weight,
      fontDisplay: 'swap',
      fontStyle: font.style || 'normal',
    };
  });
}

function injectRootSelectorsIntoUtilities(config: Config) {
  config.utilities[':root'] = {};

  for (const themeMode of themeModes) {
    const selector = getThemeSelector(
      themeMode,
      themeMode === defaultThemeMode,
    );

    // inject selector into utilities
    config.utilities[selector] = { 'color-scheme': themeMode };

    // cache selector
    cachedThemeSelectors[themeMode] = selector;
  }
}

function getThemeSelector(themeMode: string | undefined, isDefault: boolean) {
  if (!themeMode) return ':root';

  const selector = `.${themeMode}, [data-theme="${themeMode}"]`;

  return isDefault ? `:root, ${selector}` : selector;
}

function injectThemeBasedVariantDefinitions(config: Config) {
  for (const themeMode of themeModes) {
    config.variants.push({
      name: themeMode,
      definition: [`&.${themeMode}`, `&[data-theme='${themeMode}']`],
    });
  }
}

type ExpandedTokens = ExpandedTokenMap[TokenName][];

function injectTokensAndDefineVars(config: Config) {
  function injectVarDecls(group: TokenGroup, entries: Record<string, any>) {
    config.vars[group] = {
      ...(config.vars[group] || {}),
      ...entries,
    };
  }

  const queuedTokens: ExpandedTokens = Object.values(tokensMap);

  while (queuedTokens.length > 0) {
    const token = queuedTokens.shift();

    if (!token) continue;

    // expand theme tokens based on mode
    if (token.group === 'theme' && typeof token.value === 'object') {
      queuedTokens.push(...expandThemeToken(token));
      continue;
    }

    injectTokenIntoUtilities(config, token);

    injectVarDecls(token.group, {
      [chopGroupFromName(token)]: getPropValue(token),
    });
  }

  injectVarDecls('color', {
    current: 'currentColor',
    transparent: 'transparent',
  });

  injectVarDecls('lineHeight', { 0: '0', none: '1' });
}

function expandThemeToken(token: ExpandedTokens[number]) {
  if (token.group !== 'theme') return [];

  const modes = Object.keys(token.value);

  return modes.map((mode) => {
    const typedMode = mode as ThemeMode;
    const refToken = token.isAlias
      ? (tokensMap[token.value[typedMode]] as Token)
      : null;

    return {
      ...token,
      group: refToken?.group || token.type,
      value: token.value[typedMode],
      themeMode: mode,
    } as any;
  });
}

function injectTokenIntoUtilities(
  config: Config,
  token: ExpandedTokens[number],
) {
  const tokenThemeMode = token.themeMode || '';
  const selector = cachedThemeSelectors[tokenThemeMode] || ':root';

  config.utilities[selector][token.cssVarName] =
    toTailwindCompliantTokenValue(token);
}

function injectTypographyComponents(config: Config) {
  // collect all font-size token names
  const keys = Object.keys(groupedTokensMap.typography);
  const fontSizeKeys = keys.filter((key) => key.startsWith('font-size-'));

  // extract the typography names from them
  const typographyNames = fontSizeKeys.map((fontSizeKey) =>
    fontSizeKey.replace('font-size-', ''),
  );

  // populate components
  for (const typographyName of typographyNames) {
    config.components[`.typography-${typographyName}`] = {
      fontSize: getPropValue(tokensMap[`font-size-${typographyName}` as keyof TokenMap]),
      lineHeight: getPropValue(tokensMap[`line-height-${typographyName}` as keyof TokenMap]),
      letterSpacing: getPropValue(
        tokensMap[`letter-spacing-${typographyName}` as keyof TokenMap],
      ),
    };
  }
}

// transform the values into tailwind compliant ones,
// colors are transformed into R G B form as specified in the tailwind
// documentation <https://tailwindcss.com/docs/customizing-colors#using-css-variables>
// fontSizes are transformed from px to rem if necessary
function toTailwindCompliantTokenValue(token: Token) {
  if (typeof token.value === 'object') return null;

  if (token.isAlias) {
    return `var(${tokensMap[token.value]?.cssVarName ?? ''})`;
  }

  if (token.type === 'color') {
    return toTailwindCompliantColorValue(token.value);
  }

  if (token.group === 'fontSize') {
    return `${pxToRem(token.value)}rem`;
  }

  if ('unit' in token && token.unit) {
    return `${token.value}${token.unit}`;
  }

  // tailwind automatically adds px to numeric values, if a value is not meant
  // to have unit, it should be converted to a string
  if (token.type === 'number' && token.group !== 'lineHeight') {
    return token.value;
  }

  return token.value.toString();
}

function chopGroupFromName(token: Token) {
  const tokenName = token.name;
  const groupSpecifier = `${_kebabCase(token.group)}-`;

  return tokenName.startsWith(groupSpecifier)
    ? tokenName.replace(groupSpecifier, '')
    : tokenName;
}

function getPropValue(token: Token) {
  if (typeof token.value === 'object') return '';
  if (token.type !== 'color') return `var(${token.cssVarName})`;

  const alphaSpecifier = getColorAlphaSpecifier(token);

  return `rgb(var(${token.cssVarName}) / ${alphaSpecifier})`;
}

// in complaince with
// https://tailwindcss.com/docs/text-color#changing-the-opacity
// check for the presence of alpha value in the original color
// or put the alpha value specifier
function getColorAlphaSpecifier(token: Token) {
  const tokenValue = getTokenValue(token);

  if (!tokenValue || typeof tokenValue !== 'string') {
    return `rgb(var(${token.cssVarName}))`;
  }

  const colorValue = parseColorAsRGB(tokenValue);
  const colorAlphaValue = colorValue[4] || 1;

  return colorAlphaValue !== 1 ? colorAlphaValue : '<alpha-value>';
}

function getTokenValue(token: Token) {
  if (typeof token.value === 'object') return null;
  if (token.isAlias) return tokensMap[token.value].value;

  return token.value;
}

// currently 'R G B', could also be 'H S% L%'
function toTailwindCompliantColorValue(color: string) {
  return parseColorAsRGB(color).slice(0, 3).join(' ');
}

function pxToRem(value: number) {
  return Math.round((value / baseFontSize) * 1000) / 1000;
}

function parseColorAsRGB(color: string) {
  if (!cachedParsedColors[color])
    cachedParsedColors[color] = Color(color).rgb().round().array();

  return cachedParsedColors[color];
}
