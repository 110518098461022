import { defineVariants } from '@/ui/system';

export const baseButtonVariants = defineVariants({
  slots: {
    root: 'relative isolate inline-flex select-none appearance-none items-center whitespace-nowrap align-middle font-inter font-medium outline-none',
    icon: '',
  },
  variants: {
    variant: {
      filled: '',
      ghost: '',
      outline: {
        root: 'border',
      },
      link: {
        root: 'underline',
      },
      circle: {
        root: 'rounded-[100%] h-[36px] w-[36px]',
      },
      icon: {
        root: 'h-[32px] w-[32px]',
      },
    },
    appearance: {
      primary: '',
      danger: '',
      plain: '',
    },
    size: {
      large: {
        icon: 'h-units-unit24 w-units-unit24',
      },
      medium: {
        icon: 'h-units-unit20 w-units-unit20',
      },
      small: {
        icon: 'h-units-unit16 w-units-unit16',
      },
      tiny: {
        icon: 'h-units-unit12 w-units-unit12',
      },
    },
    disabled: {
      true: {
        root: 'pointer-events-none cursor-not-allowed opacity-30',
      },
    },
    block: {
      true: {
        root: 'flex w-full !px-padding4',
      },
    },
    // more of an internal variant
    layout: {
      default: {
        root: 'justify-between',
      },
      compact: {
        root: 'justify-center',
      },
      icon: {
        root: 'justify-center',
      },
    },
  },
  compoundVariants: [
    // --------------------------------
    // filled / appearance
    // --------------------------------
    {
      variant: 'circle',
      appearance: 'primary',
      class: {
        root: [
          'bg-inset-default',
          'hover:bg-feedback-information-ghost-hovered',
          'active:bg-feedback-information-ghost-pressed',
          'text-feedback-information-ghost-text',
        ],
      },
    },
    {
      variant: 'circle',
      appearance: 'plain',
      class: {
        root: [
          'bg-inset-default',
          'hover:bg-feedback-information-ghost-hovered',
          'active:bg-feedback-information-ghost-pressed',
          'text-content-heading-alternative',
        ],
      },
    },
    {
      variant: 'filled',
      appearance: 'primary',
      class: {
        root: [
          'bg-feedback-information-filled-default',
          'hover:bg-feedback-information-filled-hovered',
          'active:bg-feedback-information-filled-pressed',
          'text-feedback-information-filled-text',
        ],
      },
    },
    {
      variant: 'filled',
      appearance: 'danger',
      class: {
        root: [
          'bg-feedback-danger-filled-default',
          'hover:bg-feedback-danger-filled-hovered',
          'active:bg-feedback-danger-filled-pressed',
          'text-feedback-danger-filled-text',
        ],
      },
    },
    {
      variant: 'filled',
      appearance: 'plain',
      class: {
        root: [
          'bg-feedback-neutral-filled-default',
          'hover:bg-feedback-neutral-filled-hovered',
          'active:bg-feedback-neutral-filled-pressed',
          'text-feedback-neutral-filled-text',
        ],
      },
    },
    // --------------------------------
    // ghost / appearance
    // --------------------------------
    {
      variant: 'ghost',
      appearance: 'primary',
      class: {
        root: [
          'bg-feedback-information-ghost-default',
          'hover:bg-feedback-information-ghost-hovered',
          'active:bg-feedback-information-ghost-pressed',
          'text-feedback-information-ghost-text',
        ],
      },
    },
    {
      variant: 'ghost',
      appearance: 'danger',
      class: {
        root: [
          'bg-feedback-danger-ghost-default',
          'hover:bg-feedback-danger-ghost-hovered',
          'active:bg-feedback-danger-ghost-pressed',
          'text-feedback-danger-ghost-text',
        ],
      },
    },
    {
      variant: 'ghost',
      appearance: 'plain',
      class: {
        root: [
          'bg-feedback-neutral-ghost-default',
          'hover:bg-feedback-neutral-ghost-hovered',
          'active:bg-feedback-neutral-ghost-pressed',
          'text-feedback-neutral-ghost-text',
        ],
      },
    },
    // --------------------------------
    // outline / appearance
    // --------------------------------
    {
      variant: 'outline',
      appearance: 'primary',
      class: {
        root: [
          'bg-transparent',
          'hover:bg-feedback-information-outline-hovered',
          'active:bg-feedback-information-outline-pressed',
          'text-feedback-information-outline-text',
          'border border-feedback-information-outline-default',
        ],
      },
    },
    {
      variant: 'outline',
      appearance: 'danger',
      class: {
        root: [
          'bg-transparent',
          'border border-feedback-danger-outline-default',
          'hover:bg-feedback-danger-outline-hovered',
          'active:bg-feedback-danger-outline-pressed',
          'text-feedback-danger-outline-text',
        ],
      },
    },
    {
      variant: 'outline',
      appearance: 'plain',
      class: {
        root: [
          'bg-transparent',
          'border border-feedback-neutral-outline-default',
          'hover:bg-feedback-neutral-outline-hovered',
          'active:bg-feedback-neutral-outline-pressed',
          'text-feedback-neutral-outline-text',
        ],
      },
    },
    // --------------------------------
    // link / appearance
    // --------------------------------
    {
      variant: 'link',
      appearance: 'primary',
      class: {
        root: [
          'text-content-link-default',
          'hover:text-content-link-hovered',
          'active:text-content-link-pressed',
          'visited:text-content-link-visited',
        ],
      },
    },
    {
      variant: 'link',
      appearance: 'danger',
      class: {
        root: [
          'text-feedback-danger-filled-default',
          'hover:text-feedback-danger-filled-hovered',
          'active:text-feedback-danger-filled-pressed',
          'visited:text-feedback-danger-filled-visited',
        ],
      },
    },
    // --------------------------
    // !link / size
    // --------------------------
    {
      variant: ['filled', 'ghost', 'outline'],
      size: 'large',
      class: {
        root: 'typography-body-large h-components-button-button4 gap-units-unit8 rounded-radius5',
      },
    },
    {
      variant: ['filled', 'ghost', 'outline'],
      size: 'medium',
      class: {
        root: 'typography-body-default h-components-button-button3 gap-units-unit8 rounded-radius4',
      },
    },
    {
      variant: ['filled', 'ghost', 'outline'],
      size: 'small',
      class: {
        root: 'typography-body-small h-components-button-button2 gap-units-unit4 rounded-radius3',
      },
    },
    {
      variant: ['filled', 'ghost', 'outline'],
      size: 'tiny',
      class: {
        root: 'typography-body-x-small h-components-button-button1 gap-spacing-spacing2 rounded-radius1',
      },
    },
    // --------------------------
    // link / size
    // --------------------------
    {
      variant: 'link',
      size: 'large',
      class: {
        root: 'typography-utility-link-large',
      },
    },
    {
      variant: 'link',
      size: 'medium',
      class: {
        root: 'typography-utility-link-default',
      },
    },
    {
      variant: 'link',
      size: 'small',
      class: {
        root: 'typography-utility-link-small',
      },
    },
    {
      variant: 'link',
      size: 'tiny',
      class: {
        root: 'typography-utility-link-x-small',
      },
    },
    // --------------------------
    // default-layout / !link / size
    // --------------------------
    {
      layout: 'default',
      variant: ['filled', 'ghost', 'outline'],
      size: 'large',
      class: {
        root: 'px-padding9 data-[has-end-icon]:pr-padding5 data-[has-start-icon]:pl-padding5',
      },
    },
    {
      layout: 'default',
      variant: ['filled', 'ghost', 'outline'],
      size: 'medium',
      class: {
        root: 'px-padding8 data-[has-end-icon]:pr-padding4 data-[has-start-icon]:pl-padding4',
      },
    },
    {
      layout: 'default',
      variant: ['filled', 'ghost', 'outline'],
      size: 'small',
      class: {
        root: 'px-padding7 data-[has-end-icon]:pr-padding2 data-[has-start-icon]:pl-padding2',
      },
    },
    {
      layout: 'default',
      variant: ['filled', 'ghost', 'outline'],
      size: 'tiny',
      class: {
        root: 'px-padding6 data-[has-end-icon]:pr-padding2 data-[has-start-icon]:pl-padding2',
      },
    },
    // --------------------------
    // compact-layout / size
    // --------------------------
    {
      layout: 'compact',
      variant: ['filled', 'ghost', 'outline'],
      size: 'large',
      class: {
        root: 'px-padding7',
      },
    },
    {
      layout: 'compact',
      variant: ['filled', 'ghost', 'outline'],
      size: 'medium',
      class: {
        root: 'px-padding6',
      },
    },
    {
      layout: 'compact',
      variant: ['filled', 'ghost', 'outline'],
      size: 'small',
      class: {
        root: 'px-padding5',
      },
    },
    {
      size: 'tiny',
      variant: ['filled', 'ghost', 'outline'],
      layout: 'compact',
      class: {
        root: 'px-padding4',
      },
    },
    // -----------------------------
    // icon-layout / size
    // -----------------------------
    {
      size: 'large',
      variant: ['filled', 'ghost', 'outline'],
      layout: 'icon',
      class: {
        root: 'w-components-button-button4 p-padding5',
      },
    },
    {
      size: 'medium',
      variant: ['filled', 'ghost', 'outline'],
      layout: 'icon',
      class: {
        root: 'w-components-button-button3 p-padding4',
      },
    },
    {
      size: 'small',
      variant: ['filled', 'ghost', 'outline'],
      layout: 'icon',
      class: {
        root: 'w-components-button-button2 p-padding3',
      },
    },
    {
      size: 'tiny',
      variant: ['filled', 'ghost', 'outline'],
      layout: 'icon',
      class: {
        root: 'w-components-button-button1 p-padding2',
      },
    },
  ],
  defaultVariants: {
    appearance: 'primary',
    variant: 'filled',
    size: 'large',
    layout: 'compact',
  },
});

export type BaseButtonVariants = typeof baseButtonVariants;
