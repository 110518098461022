import { defaultConfig } from 'tailwind-variants';
import { getResolvedConfig } from '../../tailwind-plugin';

type TVConfig = typeof defaultConfig;

const twConfig = getResolvedConfig();

export const tvConfig: TVConfig = {
  twMerge: true,
  twMergeConfig: {
    theme: {
      colors: Object.keys(twConfig.vars.color ?? {}),
      spacing: Object.keys(twConfig.vars.size ?? {}),
      borderRadius: Object.keys(twConfig.vars.radius ?? {}),
      padding: Object.keys({ ...twConfig.vars.padding, ...twConfig.vars.size }),
      gap: Object.keys(twConfig.vars.size ?? {}),
    },
    classGroups: {
      fontFamily: [{ font: Object.keys(twConfig.vars.fontFamily ?? {}) }],
      fontSize: [{ text: Object.keys(twConfig.vars.fontSize ?? {}) }],
      lineHeight: [{ leading: Object.keys(twConfig.vars.lineHeight ?? {}) }],
      letterSpacing: [{ tracking: Object.keys(twConfig.vars.letterSpacing!) }],
      typography: Object.keys(twConfig.components)
        .filter((key) => key.startsWith('.typography'))
        .map((key) => key.slice(1)), // remove the dot,
    },
  },
};
