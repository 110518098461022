import React from 'react';

import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';
import { createComponent } from '@/ui/system';

import { cn } from '@/lib/utils';

import { BaseButton, BaseButtonProps } from '../base-button';

export interface ButtonProps extends BaseButtonProps {
  loading?: boolean;
}

export const Button = createComponent<ButtonProps>(
  (
    {
      children,
      startIcon,
      endIcon,
      size = 'medium',
      loading = false,
      disabled = false,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const spinnerSize = {
      large: 'w-units-unit24 h-units-unit24',
      medium: 'w-units-unit20 h-units-unit20',
      small: 'w-units-unit16 h-units-unit16',
      tiny: 'w-units-unit12 h-units-unit12',
    }[size];

    const LoadingComponent = loading ? 'span' : React.Fragment;
    const loadingComponentProps = loading
      ? {
          className: cn({ 'opacity-0': loading }),
        }
      : {};

    return (
      <BaseButton
        {...props}
        ref={ref}
        type={type}
        size={size}
        layout="default"
        endIcon={loading || endIcon}
        disabled={disabled || loading}
        startIcon={loading || startIcon}
        aria-label={loading ? 'Loading' : props['aria-label']}
      >
        <LoadingComponent {...loadingComponentProps}>
          {children}
        </LoadingComponent>

        {loading && (
          <span
            aria-hidden="true"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <KamonaSpinner className={spinnerSize} />
          </span>
        )}
      </BaseButton>
    );
  },
);

Button.displayName = 'Button';
