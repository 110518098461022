import { type ReactNode, useCallback } from 'react';

import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  dataAttr,
  toNonInteractiveElement,
  useTailwindVariants,
} from '@/ui/system';

import { BaseButtonVariants, baseButtonVariants } from './base-button.cx';

type BaseProps = HTMLCustomVariantsAwareProps<'button', BaseButtonVariants>;

export interface BaseButtonProps extends BaseProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const BaseButton = createComponent<BaseButtonProps>(
  (
    {
      as: Component = 'button',
      layout = 'compact',
      startIcon,
      endIcon,
      children,
      role = 'button',
      ...props
    },
    ref,
  ) => {
    const { classNames, ownProps } = useTailwindVariants(
      props,
      baseButtonVariants,
    );

    const renderIcon = useCallback(
      (icon: ReactNode) => {
        const usePlaceholder = !icon && (!layout || layout === 'default');

        return usePlaceholder
          ? toNonInteractiveElement(<span />)
          : toNonInteractiveElement(icon, { className: classNames.icon });
      },
      [classNames.icon, layout],
    );

    return (
      <Component
        ref={ref}
        {...ownProps}
        role={role}
        data-has-start-icon={dataAttr(!!startIcon)}
        data-has-end-icon={dataAttr(!!endIcon)}
        className={classNames.root}
      >
        {startIcon && renderIcon(startIcon)}
        {children}
        {endIcon && renderIcon(endIcon)}
      </Component>
    );
  },
);

BaseButton.displayName = 'BaseButton';
