// ---------------------------------
// DO NOT MODIFY, generated file
// ---------------------------------
import { sizeTokensMap } from './size-tokens.gen';
import { colorTokensMap } from './color-tokens.gen';
import { themeTokensMap, themeModes } from './theme-tokens.gen';
import { typographyTokensMap } from './typography-tokens.gen';

// token types
export type TokenMap = typeof tokensMap;
export type TokenName = keyof TokenMap;
export type Token = TokenMap[TokenName];
export type TokenMapGroup = keyof typeof groupedTokensMap;
export type TokenGroup = (typeof tokenGroups)[number];
// --------------
export type SizeTokens = typeof sizeTokensMap;
export type ColorTokens = typeof colorTokensMap;
export type ThemeTokens = typeof themeTokensMap;
export type TypographyTokens = typeof typographyTokensMap;

// token modes
export type ThemeMode = (typeof themeModes)[number];

export const tokenGroups = ["padding","radius","fontSize","fontFamily","lineHeight","fontWeight","letterSpacing","size","color","theme","typography"] as const;

export const tokensMap = {
  ...sizeTokensMap,
  ...colorTokensMap,
  ...themeTokensMap,
  ...typographyTokensMap,
};

export const groupedTokensMap = {
  size: sizeTokensMap,
  color: colorTokensMap,
  theme: themeTokensMap,
  typography: typographyTokensMap,
};

export { themeModes };

export * from './fonts.gen';