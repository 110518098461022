import { forwardRef } from 'react';
import {
  ComponentDefinition,
  CustomComponent,
  HTMLCustomProps,
} from './create-component.types';

export const createComponent = <Props extends HTMLCustomProps>(
  component: ComponentDefinition<Props>,
): CustomComponent<Props> =>
  forwardRef(component) as unknown as CustomComponent<Props>;
