import { ElementType } from 'react';
import { tv as tvBase, type TV, type VariantProps } from 'tailwind-variants';
import { tvConfig } from '../shared/tailwind-variants.config';
import type {
  VariantClassNameProps,
  VariantsFn,
} from '../shared/tailwind-variants.types';
import { Assign, HTMLCustomProps } from '../create-component';

export type CustomVariantProps<V extends VariantsFn> = VariantProps<V>;

export type HTMLCustomVariantsAwareProps<
  E extends ElementType,
  V extends VariantsFn,
> = Assign<HTMLCustomProps<E>, CustomVariantProps<V>, VariantClassNameProps<V>>;

export const defineVariants: TV = (options) => {
  return tvBase(options, tvConfig);
};
