import { GenerateClassNames, SizeTokenNamesWithPrefix } from './shared/types';

type SizeTokenNames = SizeTokenNamesWithPrefix<'units' | 'components'>;

export const width: GenerateClassNames<'w-', SizeTokenNames> = {
  'units-unit0': 'w-units-unit0',
  'units-unit2': 'w-units-unit2',
  'units-unit4': 'w-units-unit4',
  'units-unit6': 'w-units-unit6',
  'units-unit8': 'w-units-unit8',
  'units-unit10': 'w-units-unit10',
  'units-unit12': 'w-units-unit12',
  'units-unit14': 'w-units-unit14',
  'units-unit16': 'w-units-unit16',
  'units-unit20': 'w-units-unit20',
  'units-unit24': 'w-units-unit24',
  'units-unit28': 'w-units-unit28',
  'units-unit32': 'w-units-unit32',
  'units-unit40': 'w-units-unit40',
  'units-unit48': 'w-units-unit48',
  'units-unit56': 'w-units-unit56',
  'units-unit64': 'w-units-unit64',
  'units-unit72': 'w-units-unit72',
  'units-unit80': 'w-units-unit80',
  'units-unit96': 'w-units-unit96',
  'components-button-button1': 'w-components-button-button1',
  'components-button-button2': 'w-components-button-button2',
  'components-button-button3': 'w-components-button-button3',
  'components-button-button4': 'w-components-button-button4',
  'components-spinner-spinner1': 'w-components-spinner-spinner1',
  'components-icon-icon1': 'w-components-icon-icon1',
  'components-icon-icon2': 'w-components-icon-icon2',
  'components-icon-icon3': 'w-components-icon-icon3',
  'components-icon-icon4': 'w-components-icon-icon4',
  'components-icon-icon5': 'w-components-icon-icon5',
  'components-icon-icon6': 'w-components-icon-icon6',
  'components-icon-icon7': 'w-components-icon-icon7',
  'components-spinner-spinner2': 'w-components-spinner-spinner2',
  'components-spinner-spinner3': 'w-components-spinner-spinner3',
  'components-switch-switch1': 'w-components-switch-switch1',
  'components-switch-switch2': 'w-components-switch-switch2',
  'components-switch-switch3': 'w-components-switch-switch3',
  'components-avatar-avatar1': 'w-components-avatar-avatar1',
  'components-flag-flag1': 'w-components-flag-flag1',
  'components-avatar-avatar2': 'w-components-avatar-avatar2',
  'components-flag-flag2': 'w-components-flag-flag2',
  'components-flag-flag3': 'w-components-flag-flag3',
  'components-avatar-avatar3': 'w-components-avatar-avatar3',
  'components-avatar-avatar4': 'w-components-avatar-avatar4',
  'components-avatar-avatar5': 'w-components-avatar-avatar5',
  'components-avatar-avater6': 'w-components-avatar-avater6',
  'components-input-input1': 'w-components-input-input1',
  'components-input-input2': 'w-components-input-input2',
  'components-input-input3': 'w-components-input-input3',
  'components-input-input4': 'w-components-input-input4',
  'components-input-input5': 'w-components-input-input5',
  'components-chips-chip1': 'w-components-chips-chip1',
  'components-chips-chip2': 'w-components-chips-chip2',
  'components-chips-chip3': 'w-components-chips-chip3',
};

export const height: GenerateClassNames<'h-', SizeTokenNames> = {
  'units-unit0': 'h-units-unit0',
  'units-unit2': 'h-units-unit2',
  'units-unit4': 'h-units-unit4',
  'units-unit6': 'h-units-unit6',
  'units-unit8': 'h-units-unit8',
  'units-unit10': 'h-units-unit10',
  'units-unit12': 'h-units-unit12',
  'units-unit14': 'h-units-unit14',
  'units-unit16': 'h-units-unit16',
  'units-unit20': 'h-units-unit20',
  'units-unit24': 'h-units-unit24',
  'units-unit28': 'h-units-unit28',
  'units-unit32': 'h-units-unit32',
  'units-unit40': 'h-units-unit40',
  'units-unit48': 'h-units-unit48',
  'units-unit56': 'h-units-unit56',
  'units-unit64': 'h-units-unit64',
  'units-unit72': 'h-units-unit72',
  'units-unit80': 'h-units-unit80',
  'units-unit96': 'h-units-unit96',
  'components-button-button1': 'h-components-button-button1',
  'components-button-button2': 'h-components-button-button2',
  'components-button-button3': 'h-components-button-button3',
  'components-button-button4': 'h-components-button-button4',
  'components-spinner-spinner1': 'h-components-spinner-spinner1',
  'components-icon-icon1': 'h-components-icon-icon1',
  'components-icon-icon2': 'h-components-icon-icon2',
  'components-icon-icon3': 'h-components-icon-icon3',
  'components-icon-icon4': 'h-components-icon-icon4',
  'components-icon-icon5': 'h-components-icon-icon5',
  'components-icon-icon6': 'h-components-icon-icon6',
  'components-icon-icon7': 'h-components-icon-icon7',
  'components-spinner-spinner2': 'h-components-spinner-spinner2',
  'components-spinner-spinner3': 'h-components-spinner-spinner3',
  'components-switch-switch1': 'h-components-switch-switch1',
  'components-switch-switch2': 'h-components-switch-switch2',
  'components-switch-switch3': 'h-components-switch-switch3',
  'components-avatar-avatar1': 'h-components-avatar-avatar1',
  'components-flag-flag1': 'h-components-flag-flag1',
  'components-avatar-avatar2': 'h-components-avatar-avatar2',
  'components-flag-flag2': 'h-components-flag-flag2',
  'components-flag-flag3': 'h-components-flag-flag3',
  'components-avatar-avatar3': 'h-components-avatar-avatar3',
  'components-avatar-avatar4': 'h-components-avatar-avatar4',
  'components-avatar-avatar5': 'h-components-avatar-avatar5',
  'components-avatar-avater6': 'h-components-avatar-avater6',
  'components-input-input1': 'h-components-input-input1',
  'components-input-input2': 'h-components-input-input2',
  'components-input-input3': 'h-components-input-input3',
  'components-input-input4': 'h-components-input-input4',
  'components-input-input5': 'h-components-input-input5',
  'components-chips-chip1': 'h-components-chips-chip1',
  'components-chips-chip2': 'h-components-chips-chip2',
  'components-chips-chip3': 'h-components-chips-chip3',
};
