import {
  ColorTokenNamesWithPrefix,
  GenerateClassNames,
  ThemeTokenNamesWithPrefix,
} from './shared/types';

type ColorTokenNames = ColorTokenNamesWithPrefix<''>;
type ThemeTokenNames = ThemeTokenNamesWithPrefix<''>;

export const textColor: GenerateClassNames<
  'text-',
  ColorTokenNames | ThemeTokenNames
> = {
  'primary-l1': 'text-primary-l1',
  'primary-l2': 'text-primary-l2',
  'primary-l3': 'text-primary-l3',
  'primary-l4': 'text-primary-l4',
  'primary-l5': 'text-primary-l5',
  'primary-l6': 'text-primary-l6',
  'primary-l7': 'text-primary-l7',
  'primary-base': 'text-primary-base',
  'primary-d7': 'text-primary-d7',
  'primary-d6': 'text-primary-d6',
  'primary-d5': 'text-primary-d5',
  'primary-d4': 'text-primary-d4',
  'primary-d3': 'text-primary-d3',
  'primary-d2': 'text-primary-d2',
  'primary-d1': 'text-primary-d1',
  'secondary-midnight-blue-l1': 'text-secondary-midnight-blue-l1',
  'secondary-midnight-blue-l2': 'text-secondary-midnight-blue-l2',
  'secondary-midnight-blue-l3': 'text-secondary-midnight-blue-l3',
  'secondary-midnight-blue-l4': 'text-secondary-midnight-blue-l4',
  'secondary-midnight-blue-l5': 'text-secondary-midnight-blue-l5',
  'secondary-midnight-blue-l6': 'text-secondary-midnight-blue-l6',
  'secondary-midnight-blue-l7': 'text-secondary-midnight-blue-l7',
  'secondary-midnight-blue-base': 'text-secondary-midnight-blue-base',
  'secondary-lite-turquoise-l1': 'text-secondary-lite-turquoise-l1',
  'secondary-lite-turquoise-l2': 'text-secondary-lite-turquoise-l2',
  'secondary-midnight-blue-d5': 'text-secondary-midnight-blue-d5',
  'secondary-lite-turquoise-l3': 'text-secondary-lite-turquoise-l3',
  'secondary-midnight-blue-d4': 'text-secondary-midnight-blue-d4',
  'secondary-lite-turquoise-l4': 'text-secondary-lite-turquoise-l4',
  'secondary-midnight-blue-d3': 'text-secondary-midnight-blue-d3',
  'secondary-lite-turquoise-l5': 'text-secondary-lite-turquoise-l5',
  'secondary-midnight-blue-d2': 'text-secondary-midnight-blue-d2',
  'secondary-midnight-blue-d1': 'text-secondary-midnight-blue-d1',
  'secondary-lite-turquoise-base': 'text-secondary-lite-turquoise-base',
  'secondary-lite-almond-l1': 'text-secondary-lite-almond-l1',
  'secondary-lite-turquoise-d7': 'text-secondary-lite-turquoise-d7',
  'secondary-lite-turquoise-d6': 'text-secondary-lite-turquoise-d6',
  'secondary-lite-almond-l2': 'text-secondary-lite-almond-l2',
  'secondary-lite-almond-l3': 'text-secondary-lite-almond-l3',
  'secondary-lite-turquoise-d5': 'text-secondary-lite-turquoise-d5',
  'secondary-lite-turquoise-d4': 'text-secondary-lite-turquoise-d4',
  'secondary-lite-almond-l4': 'text-secondary-lite-almond-l4',
  'secondary-lite-turquoise-d3': 'text-secondary-lite-turquoise-d3',
  'secondary-lite-almond-l5': 'text-secondary-lite-almond-l5',
  'secondary-lite-turquoise-d2': 'text-secondary-lite-turquoise-d2',
  'secondary-lite-turquoise-d1': 'text-secondary-lite-turquoise-d1',
  'secondary-lite-almond-base': 'text-secondary-lite-almond-base',
  'secondary-lite-almond-d7': 'text-secondary-lite-almond-d7',
  'secondary-lite-almond-d6': 'text-secondary-lite-almond-d6',
  'secondary-lite-almond-d5': 'text-secondary-lite-almond-d5',
  'secondary-lite-almond-d4': 'text-secondary-lite-almond-d4',
  'secondary-lite-almond-d3': 'text-secondary-lite-almond-d3',
  'secondary-lite-almond-d2': 'text-secondary-lite-almond-d2',
  'secondary-lite-almond-d1': 'text-secondary-lite-almond-d1',
  'tertiary-rainforest-green-l1': 'text-tertiary-rainforest-green-l1',
  'tertiary-rainforest-green-l2': 'text-tertiary-rainforest-green-l2',
  'tertiary-rainforest-green-l3': 'text-tertiary-rainforest-green-l3',
  'tertiary-rainforest-green-l4': 'text-tertiary-rainforest-green-l4',
  'tertiary-rainforest-green-l5': 'text-tertiary-rainforest-green-l5',
  'tertiary-rainforest-green-l6': 'text-tertiary-rainforest-green-l6',
  'tertiary-rainforest-green-l7': 'text-tertiary-rainforest-green-l7',
  'tertiary-rainforest-green-base': 'text-tertiary-rainforest-green-base',
  'tertiary-rainforest-green-d5': 'text-tertiary-rainforest-green-d5',
  'tertiary-rainforest-green-d4': 'text-tertiary-rainforest-green-d4',
  'tertiary-rainforest-green-d3': 'text-tertiary-rainforest-green-d3',
  'tertiary-rainforest-green-d2': 'text-tertiary-rainforest-green-d2',
  'tertiary-rainforest-green-d1': 'text-tertiary-rainforest-green-d1',
  'tertiary-lite-lemon-l1': 'text-tertiary-lite-lemon-l1',
  'tertiary-lite-lavender-l1': 'text-tertiary-lite-lavender-l1',
  'tertiary-lite-lavender-l2': 'text-tertiary-lite-lavender-l2',
  'tertiary-lite-lavender-l3': 'text-tertiary-lite-lavender-l3',
  'tertiary-lite-lavender-l4': 'text-tertiary-lite-lavender-l4',
  'tertiary-lite-lavender-l5': 'text-tertiary-lite-lavender-l5',
  'tertiary-lite-lavender-base': 'text-tertiary-lite-lavender-base',
  'tertiary-lite-lavender-d7': 'text-tertiary-lite-lavender-d7',
  'tertiary-lite-lavender-d6': 'text-tertiary-lite-lavender-d6',
  'tertiary-lite-lavender-d5': 'text-tertiary-lite-lavender-d5',
  'tertiary-lite-lavender-d4': 'text-tertiary-lite-lavender-d4',
  'tertiary-lite-lavender-d3': 'text-tertiary-lite-lavender-d3',
  'tertiary-lite-lavender-d2': 'text-tertiary-lite-lavender-d2',
  'tertiary-lite-lavender-d1': 'text-tertiary-lite-lavender-d1',
  'tertiary-lite-lemon-l2': 'text-tertiary-lite-lemon-l2',
  'tertiary-lite-lemon-l3': 'text-tertiary-lite-lemon-l3',
  'tertiary-lite-lemon-l4': 'text-tertiary-lite-lemon-l4',
  'tertiary-lite-lemon-l5': 'text-tertiary-lite-lemon-l5',
  'tertiary-lite-lemon-base': 'text-tertiary-lite-lemon-base',
  'tertiary-lite-lemon-d7': 'text-tertiary-lite-lemon-d7',
  'tertiary-lite-lemon-d6': 'text-tertiary-lite-lemon-d6',
  'tertiary-lite-lemon-d5': 'text-tertiary-lite-lemon-d5',
  'tertiary-lite-lemon-d4': 'text-tertiary-lite-lemon-d4',
  'tertiary-lite-lemon-d3': 'text-tertiary-lite-lemon-d3',
  'tertiary-lite-lemon-d2': 'text-tertiary-lite-lemon-d2',
  'tertiary-lite-lemon-d1': 'text-tertiary-lite-lemon-d1',
  'tertiary-deep-maroon-l1': 'text-tertiary-deep-maroon-l1',
  'tertiary-deep-maroon-l2': 'text-tertiary-deep-maroon-l2',
  'tertiary-deep-maroon-l3': 'text-tertiary-deep-maroon-l3',
  'tertiary-deep-maroon-l4': 'text-tertiary-deep-maroon-l4',
  'tertiary-deep-maroon-l5': 'text-tertiary-deep-maroon-l5',
  'tertiary-deep-maroon-l6': 'text-tertiary-deep-maroon-l6',
  'tertiary-deep-maroon-l7': 'text-tertiary-deep-maroon-l7',
  'tertiary-deep-maroon-base': 'text-tertiary-deep-maroon-base',
  'tertiary-deep-maroon-d5': 'text-tertiary-deep-maroon-d5',
  'tertiary-deep-maroon-d4': 'text-tertiary-deep-maroon-d4',
  'tertiary-deep-maroon-d3': 'text-tertiary-deep-maroon-d3',
  'tertiary-deep-maroon-d2': 'text-tertiary-deep-maroon-d2',
  'tertiary-deep-maroon-d1': 'text-tertiary-deep-maroon-d1',
  'tertiary-lite-peach-l1': 'text-tertiary-lite-peach-l1',
  'tertiary-lite-peach-l2': 'text-tertiary-lite-peach-l2',
  'tertiary-lite-peach-l3': 'text-tertiary-lite-peach-l3',
  'tertiary-lite-peach-l4': 'text-tertiary-lite-peach-l4',
  'tertiary-lite-peach-l5': 'text-tertiary-lite-peach-l5',
  'tertiary-lite-peach-base': 'text-tertiary-lite-peach-base',
  'tertiary-lite-peach-d7': 'text-tertiary-lite-peach-d7',
  'tertiary-lite-peach-d6': 'text-tertiary-lite-peach-d6',
  'tertiary-lite-peach-d5': 'text-tertiary-lite-peach-d5',
  'tertiary-lite-peach-d4': 'text-tertiary-lite-peach-d4',
  'tertiary-lite-peach-d3': 'text-tertiary-lite-peach-d3',
  'tertiary-lite-peach-d2': 'text-tertiary-lite-peach-d2',
  'tertiary-lite-peach-d1': 'text-tertiary-lite-peach-d1',
  'tertiary-lite-mint-l1': 'text-tertiary-lite-mint-l1',
  'tertiary-lite-mint-l2': 'text-tertiary-lite-mint-l2',
  'tertiary-lite-mint-l3': 'text-tertiary-lite-mint-l3',
  'tertiary-lite-mint-l4': 'text-tertiary-lite-mint-l4',
  'tertiary-lite-mint-l5': 'text-tertiary-lite-mint-l5',
  'tertiary-lite-mint-base': 'text-tertiary-lite-mint-base',
  'tertiary-lite-mint-d7': 'text-tertiary-lite-mint-d7',
  'tertiary-lite-mint-d6': 'text-tertiary-lite-mint-d6',
  'tertiary-lite-mint-d5': 'text-tertiary-lite-mint-d5',
  'tertiary-lite-mint-d4': 'text-tertiary-lite-mint-d4',
  'tertiary-lite-mint-d3': 'text-tertiary-lite-mint-d3',
  'tertiary-lite-mint-d2': 'text-tertiary-lite-mint-d2',
  'tertiary-lite-mint-d1': 'text-tertiary-lite-mint-d1',
  'semantic-green-l1': 'text-semantic-green-l1',
  'semantic-green-l2': 'text-semantic-green-l2',
  'semantic-green-l3': 'text-semantic-green-l3',
  'semantic-green-l4': 'text-semantic-green-l4',
  'semantic-green-l5': 'text-semantic-green-l5',
  'semantic-green-l6': 'text-semantic-green-l6',
  'semantic-green-l7': 'text-semantic-green-l7',
  'semantic-green-base': 'text-semantic-green-base',
  'semantic-green-d7': 'text-semantic-green-d7',
  'semantic-green-d6': 'text-semantic-green-d6',
  'semantic-green-d5': 'text-semantic-green-d5',
  'semantic-green-d4': 'text-semantic-green-d4',
  'semantic-green-d3': 'text-semantic-green-d3',
  'semantic-green-d2': 'text-semantic-green-d2',
  'semantic-green-d1': 'text-semantic-green-d1',
  'semantic-red-l1': 'text-semantic-red-l1',
  'semantic-red-l2': 'text-semantic-red-l2',
  'semantic-red-l3': 'text-semantic-red-l3',
  'semantic-red-l4': 'text-semantic-red-l4',
  'semantic-red-l5': 'text-semantic-red-l5',
  'semantic-red-l6': 'text-semantic-red-l6',
  'semantic-red-l7': 'text-semantic-red-l7',
  'semantic-red-base': 'text-semantic-red-base',
  'semantic-red-d7': 'text-semantic-red-d7',
  'semantic-red-d6': 'text-semantic-red-d6',
  'semantic-red-d5': 'text-semantic-red-d5',
  'semantic-red-d4': 'text-semantic-red-d4',
  'semantic-red-d3': 'text-semantic-red-d3',
  'semantic-red-d2': 'text-semantic-red-d2',
  'semantic-red-d1': 'text-semantic-red-d1',
  'semantic-yellow-l1': 'text-semantic-yellow-l1',
  'semantic-yellow-l2': 'text-semantic-yellow-l2',
  'semantic-yellow-l3': 'text-semantic-yellow-l3',
  'semantic-yellow-l4': 'text-semantic-yellow-l4',
  'semantic-yellow-l5': 'text-semantic-yellow-l5',
  'semantic-yellow-l6': 'text-semantic-yellow-l6',
  'semantic-yellow-l7': 'text-semantic-yellow-l7',
  'semantic-yellow-base': 'text-semantic-yellow-base',
  'semantic-yellow-d7': 'text-semantic-yellow-d7',
  'semantic-yellow-d6': 'text-semantic-yellow-d6',
  'semantic-yellow-d5': 'text-semantic-yellow-d5',
  'semantic-yellow-d4': 'text-semantic-yellow-d4',
  'semantic-yellow-d3': 'text-semantic-yellow-d3',
  'semantic-yellow-d2': 'text-semantic-yellow-d2',
  'semantic-yellow-d1': 'text-semantic-yellow-d1',
  'neutral-black': 'text-neutral-black',
  'neutral-s14': 'text-neutral-s14',
  'neutral-s13': 'text-neutral-s13',
  'neutral-s12': 'text-neutral-s12',
  'neutral-s11': 'text-neutral-s11',
  'neutral-s10': 'text-neutral-s10',
  'neutral-s9': 'text-neutral-s9',
  'neutral-s8': 'text-neutral-s8',
  'neutral-s7': 'text-neutral-s7',
  'neutral-s6': 'text-neutral-s6',
  'neutral-s5': 'text-neutral-s5',
  'neutral-s4': 'text-neutral-s4',
  'neutral-s3': 'text-neutral-s3',
  'neutral-s2': 'text-neutral-s2',
  'neutral-s1': 'text-neutral-s1',
  'neutral-white': 'text-neutral-white',
  'content-heading-default': 'text-content-heading-default',
  'content-heading-alternative': 'text-content-heading-alternative',
  'content-subtext-default': 'text-content-subtext-default',
  'content-subtext-alternative': 'text-content-subtext-alternative',
  'content-label-default': 'text-content-label-default',
  'content-label-alternative': 'text-content-label-alternative',
  'content-body-default': 'text-content-body-default',
  'content-link-default': 'text-content-link-default',
  'content-link-hovered': 'text-content-link-hovered',
  'content-link-pressed': 'text-content-link-pressed',
  'content-link-visited': 'text-content-link-visited',
  'background-page-default': 'text-background-page-default',
  'background-page-overlay': 'text-background-page-overlay',
  'background-card-default': 'text-background-card-default',
  'background-card-overlay': 'text-background-card-overlay',
  'background-inset-default': 'text-background-inset-default',
  'background-inset-overlay': 'text-background-inset-overlay',
  'feedback-information-filled-default':
    'text-feedback-information-filled-default',
  'feedback-information-filled-hovered':
    'text-feedback-information-filled-hovered',
  'feedback-information-filled-pressed':
    'text-feedback-information-filled-pressed',
  'feedback-information-filled-text': 'text-feedback-information-filled-text',
  'feedback-information-ghost-default':
    'text-feedback-information-ghost-default',
  'feedback-information-ghost-hovered':
    'text-feedback-information-ghost-hovered',
  'feedback-information-ghost-pressed':
    'text-feedback-information-ghost-pressed',
  'feedback-information-ghost-text': 'text-feedback-information-ghost-text',
  'feedback-danger-filled-default': 'text-feedback-danger-filled-default',
  'feedback-danger-ghost-default': 'text-feedback-danger-ghost-default',
  'feedback-danger-ghost-hovered': 'text-feedback-danger-ghost-hovered',
  'feedback-danger-ghost-pressed': 'text-feedback-danger-ghost-pressed',
  'feedback-danger-ghost-text': 'text-feedback-danger-ghost-text',
  'feedback-danger-filled-hovered': 'text-feedback-danger-filled-hovered',
  'feedback-danger-filled-pressed': 'text-feedback-danger-filled-pressed',
  'feedback-danger-filled-text': 'text-feedback-danger-filled-text',
  'feedback-success-filled-default': 'text-feedback-success-filled-default',
  'feedback-warning-filled-default': 'text-feedback-warning-filled-default',
  'feedback-neutral-filled-default': 'text-feedback-neutral-filled-default',
  'feedback-neutral-filled-hovered': 'text-feedback-neutral-filled-hovered',
  'feedback-neutral-filled-pressed': 'text-feedback-neutral-filled-pressed',
  'feedback-neutral-filled-text': 'text-feedback-neutral-filled-text',
  'feedback-neutral-ghost-default': 'text-feedback-neutral-ghost-default',
  'feedback-neutral-ghost-hovered': 'text-feedback-neutral-ghost-hovered',
  'feedback-neutral-ghost-pressed': 'text-feedback-neutral-ghost-pressed',
  'feedback-neutral-ghost-text': 'text-feedback-neutral-ghost-text',
  'feedback-warning-filled-hovered': 'text-feedback-warning-filled-hovered',
  'feedback-warning-filled-pressed': 'text-feedback-warning-filled-pressed',
  'feedback-warning-filled-text': 'text-feedback-warning-filled-text',
  'feedback-warning-ghost-default': 'text-feedback-warning-ghost-default',
  'feedback-warning-ghost-hovered': 'text-feedback-warning-ghost-hovered',
  'feedback-warning-ghost-pressed': 'text-feedback-warning-ghost-pressed',
  'feedback-warning-ghost-text': 'text-feedback-warning-ghost-text',
  'feedback-success-filled-hovered': 'text-feedback-success-filled-hovered',
  'feedback-success-filled-pressed': 'text-feedback-success-filled-pressed',
  'feedback-success-filled-text': 'text-feedback-success-filled-text',
  'feedback-success-ghost-default': 'text-feedback-success-ghost-default',
  'feedback-success-ghost-hovered': 'text-feedback-success-ghost-hovered',
  'feedback-success-ghost-pressed': 'text-feedback-success-ghost-pressed',
  'feedback-success-ghost-text': 'text-feedback-success-ghost-text',
  'feedback-information-outline-default':
    'text-feedback-information-outline-default',
  'feedback-information-outline-hovered':
    'text-feedback-information-outline-hovered',
  'feedback-information-outline-pressed':
    'text-feedback-information-outline-pressed',
  'feedback-information-outline-text': 'text-feedback-information-outline-text',
  'feedback-danger-outline-default': 'text-feedback-danger-outline-default',
  'feedback-danger-outline-hovered': 'text-feedback-danger-outline-hovered',
  'feedback-danger-outline-pressed': 'text-feedback-danger-outline-pressed',
  'feedback-danger-outline-text': 'text-feedback-danger-outline-text',
  'feedback-success-outline-default': 'text-feedback-success-outline-default',
  'feedback-success-outline-hovered': 'text-feedback-success-outline-hovered',
  'feedback-success-outline-pressed': 'text-feedback-success-outline-pressed',
  'feedback-success-outline-text': 'text-feedback-success-outline-text',
  'feedback-warning-outline-default': 'text-feedback-warning-outline-default',
  'feedback-warning-outline-hovered': 'text-feedback-warning-outline-hovered',
  'feedback-warning-outline-pressed': 'text-feedback-warning-outline-pressed',
  'feedback-warning-outline-text': 'text-feedback-warning-outline-text',
  'feedback-neutral-outline-default': 'text-feedback-neutral-outline-default',
  'feedback-neutral-outline-hovered': 'text-feedback-neutral-outline-hovered',
  'feedback-neutral-outline-pressed': 'text-feedback-neutral-outline-pressed',
  'feedback-neutral-outline-text': 'text-feedback-neutral-outline-text',
  'ui-input-default': 'text-ui-input-default',
  'ui-input-hovered': 'text-ui-input-hovered',
  'ui-input-focused': 'text-ui-input-focused',
  'ui-border-default': 'text-ui-border-default',
  'ui-border-alternative': 'text-ui-border-alternative',
  'ui-border-active': 'text-ui-border-active',
  'ui-border-emphasis': 'text-ui-border-emphasis',
  'ui-border-dark': 'text-ui-border-dark',
  'ui-skeleton-default': 'text-ui-skeleton-default',
  'ui-skeleton-variant': 'text-ui-skeleton-variant',
  'ui-backdrop-default': 'text-ui-backdrop-default',
  'ui-backdrop-variant': 'text-ui-backdrop-variant',
  'ui-highlight-bg': 'text-ui-highlight-bg',
  'ui-highlight-text': 'text-ui-highlight-text',
  'ui-input-disabled': 'text-ui-input-disabled',
};
