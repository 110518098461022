import { cn } from '@/lib/utils';

export function KamonaSpinner({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      aria-hidden="true"
      viewBox="0 0 24 24"
      aria-label="Loading"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('animate-spin', className)}
    >
      <path
        opacity="0.3"
        fill="currentColor"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM1.68 12C1.68 17.6996 6.30042 22.32 12 22.32C17.6996 22.32 22.32 17.6996 22.32 12C22.32 6.30042 17.6996 1.68 12 1.68C6.30042 1.68 1.68 6.30042 1.68 12Z"
      />
      <path
        fill="currentColor"
        d="M12 0.84C12 0.376081 12.3766 -0.00306044 12.8393 0.029387C14.5691 0.15067 16.2557 0.645734 17.781 1.48432C19.3064 2.32291 20.6281 3.48161 21.6574 4.87715C21.9327 5.2505 21.8144 5.77159 21.4227 6.02017V6.02017C21.031 6.26875 20.5146 6.1503 20.235 5.78011C19.3603 4.62198 18.249 3.65874 16.9717 2.95652C15.6944 2.25429 14.2856 1.83217 12.8391 1.71417C12.3767 1.67645 12 1.30392 12 0.84V0.84Z"
      />
    </svg>
  );
}
