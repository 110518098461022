import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback, useMemo } from 'react';
import { TVReturnType } from 'tailwind-variants';
import {
  ClassNameValue,
  HasSlots,
  SlotName,
  SlottedClassValue,
  VariantKey,
} from './use-tailwind-variants.type';
import { HTMLCustomProps } from '../create-component';

type ToTamedVariantsFn<V> =
  V extends TVReturnType<
    infer V_,
    infer S,
    infer B,
    infer C,
    infer EV,
    infer ES,
    infer E
  >
    ? TVReturnType<V_, S, B, C, EV, ES, E>
    : never;

type RemoveUnknown<T> = {
  [K in keyof T as unknown extends T[K] ? never : K]: T[K];
};

export function useTailwindVariants<
  P extends HTMLCustomProps,
  V extends (...args: any[]) => any,
>(props: P, variants: V) {
  // Helper types
  type VariantsFn = ToTamedVariantsFn<V>;
  type PropsWithoutClassName = Omit<P, 'className'>;
  type OwnProps =
    HasSlots<V> extends true
      ? Omit<PropsWithoutClassName, VariantKey<V>>
      : Omit<P, VariantKey<V>>;

  const typedVariantsFn = variants as unknown as VariantsFn;
  const { className: originalClassName, ...propsWithoutClassName } = props;

  // Collect all slots and variant keys including those in the extended variants
  const slots = {
    ...typedVariantsFn.slots,
    ...((typedVariantsFn.extend as VariantsFn)?.slots || {}),
  };
  const variantKeys = [
    ...typedVariantsFn.variantKeys,
    ...((typedVariantsFn.extend as VariantsFn)?.variantKeys || []),
  ];

  // Extract only the variant props
  // @ts-expect-error VariantKey
  const variantProps = pick(propsWithoutClassName, variantKeys) as unknown as RemoveUnknown<Pick<PropsWithoutClassName, VariantKey<V>>>;


  const hasSlots = !!Object.keys(slots).length;

  // Invoke the variant function
  const result = typedVariantsFn(
    hasSlots ? variantProps : { ...variantProps, className: originalClassName },
  );

  // Compute new classname
  const newClassName = useMemo(() => {
    if (!hasSlots || typeof result === 'string') return result;

    const typedResult = result as Record<SlotName<V>, unknown>;
    const className = originalClassName as SlottedClassValue<V>;

    return mapValues(typedResult, (slot, slotName) => {
      if (typeof slot !== 'function') return undefined;
      return slot({
        ...variantProps,
        className: className?.[slotName as SlotName<V>],
      });
    });
  }, [hasSlots, originalClassName, result, variantProps]) as ClassNameValue<V>;

  //remove disabled from variantKeys
  const variantKeysWithoutDisabled = variantKeys.filter(item => item !== 'disabled');
  // Extract props that aren't among the variant props
  const baseOwnProps = omit(propsWithoutClassName, variantKeysWithoutDisabled) as unknown as OwnProps;

  // Inject computed className into props if not dealing with a slotted variant
  const ownProps = hasSlots
    ? baseOwnProps
    : { ...baseOwnProps, className: newClassName };

  return {
    ownProps,
    variantProps,
    allProps: { ...ownProps, ...variantProps, className: newClassName },
    classNames: newClassName,
    extractProps: useCallback(
      <K extends keyof P>(propNames: K[]) => pick(props, propNames),
      [props],
    ),
    splitProps: useCallback(
      <K extends keyof P>(propNames: K[]) =>
        [pick(props, propNames), omit(props, propNames)] as const,
      [props],
    ),
  };
}
