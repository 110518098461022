import { GenerateClassNames, SizeTokenNamesWithPrefix } from './shared/types';

type SpacingTokenNames = SizeTokenNamesWithPrefix<'spacing' | 'units'>;

export const gap: GenerateClassNames<'gap-', SpacingTokenNames> = {
  'units-unit0': 'gap-units-unit0',
  'units-unit2': 'gap-units-unit2',
  'units-unit4': 'gap-units-unit4',
  'units-unit6': 'gap-units-unit6',
  'units-unit8': 'gap-units-unit8',
  'units-unit10': 'gap-units-unit10',
  'units-unit12': 'gap-units-unit12',
  'units-unit14': 'gap-units-unit14',
  'units-unit16': 'gap-units-unit16',
  'units-unit20': 'gap-units-unit20',
  'units-unit24': 'gap-units-unit24',
  'units-unit28': 'gap-units-unit28',
  'units-unit32': 'gap-units-unit32',
  'units-unit40': 'gap-units-unit40',
  'units-unit48': 'gap-units-unit48',
  'units-unit56': 'gap-units-unit56',
  'units-unit64': 'gap-units-unit64',
  'units-unit72': 'gap-units-unit72',
  'units-unit80': 'gap-units-unit80',
  'units-unit96': 'gap-units-unit96',
  'spacing-spacing0': 'gap-spacing-spacing0',
  'spacing-spacing1': 'gap-spacing-spacing1',
  'spacing-spacing2': 'gap-spacing-spacing2',
  'spacing-spacing3': 'gap-spacing-spacing3',
  'spacing-spacing4': 'gap-spacing-spacing4',
  'spacing-spacing5': 'gap-spacing-spacing5',
  'spacing-spacing6': 'gap-spacing-spacing6',
  'spacing-spacing7': 'gap-spacing-spacing7',
  'spacing-spacing8': 'gap-spacing-spacing8',
  'spacing-spacing9': 'gap-spacing-spacing9',
  'spacing-spacing10': 'gap-spacing-spacing10',
  'spacing-spacing11': 'gap-spacing-spacing11',
  'spacing-spacing12': 'gap-spacing-spacing12',
};

export const gapX: GenerateClassNames<'gap-x-', SpacingTokenNames> = {
  'units-unit0': 'gap-x-units-unit0',
  'units-unit2': 'gap-x-units-unit2',
  'units-unit4': 'gap-x-units-unit4',
  'units-unit6': 'gap-x-units-unit6',
  'units-unit8': 'gap-x-units-unit8',
  'units-unit10': 'gap-x-units-unit10',
  'units-unit12': 'gap-x-units-unit12',
  'units-unit14': 'gap-x-units-unit14',
  'units-unit16': 'gap-x-units-unit16',
  'units-unit20': 'gap-x-units-unit20',
  'units-unit24': 'gap-x-units-unit24',
  'units-unit28': 'gap-x-units-unit28',
  'units-unit32': 'gap-x-units-unit32',
  'units-unit40': 'gap-x-units-unit40',
  'units-unit48': 'gap-x-units-unit48',
  'units-unit56': 'gap-x-units-unit56',
  'units-unit64': 'gap-x-units-unit64',
  'units-unit72': 'gap-x-units-unit72',
  'units-unit80': 'gap-x-units-unit80',
  'units-unit96': 'gap-x-units-unit96',
  'spacing-spacing0': 'gap-x-spacing-spacing0',
  'spacing-spacing1': 'gap-x-spacing-spacing1',
  'spacing-spacing2': 'gap-x-spacing-spacing2',
  'spacing-spacing3': 'gap-x-spacing-spacing3',
  'spacing-spacing4': 'gap-x-spacing-spacing4',
  'spacing-spacing5': 'gap-x-spacing-spacing5',
  'spacing-spacing6': 'gap-x-spacing-spacing6',
  'spacing-spacing7': 'gap-x-spacing-spacing7',
  'spacing-spacing8': 'gap-x-spacing-spacing8',
  'spacing-spacing9': 'gap-x-spacing-spacing9',
  'spacing-spacing10': 'gap-x-spacing-spacing10',
  'spacing-spacing11': 'gap-x-spacing-spacing11',
  'spacing-spacing12': 'gap-x-spacing-spacing12',
};

export const gapY: GenerateClassNames<'gap-y-', SpacingTokenNames> = {
  'units-unit0': 'gap-y-units-unit0',
  'units-unit2': 'gap-y-units-unit2',
  'units-unit4': 'gap-y-units-unit4',
  'units-unit6': 'gap-y-units-unit6',
  'units-unit8': 'gap-y-units-unit8',
  'units-unit10': 'gap-y-units-unit10',
  'units-unit12': 'gap-y-units-unit12',
  'units-unit14': 'gap-y-units-unit14',
  'units-unit16': 'gap-y-units-unit16',
  'units-unit20': 'gap-y-units-unit20',
  'units-unit24': 'gap-y-units-unit24',
  'units-unit28': 'gap-y-units-unit28',
  'units-unit32': 'gap-y-units-unit32',
  'units-unit40': 'gap-y-units-unit40',
  'units-unit48': 'gap-y-units-unit48',
  'units-unit56': 'gap-y-units-unit56',
  'units-unit64': 'gap-y-units-unit64',
  'units-unit72': 'gap-y-units-unit72',
  'units-unit80': 'gap-y-units-unit80',
  'units-unit96': 'gap-y-units-unit96',
  'spacing-spacing0': 'gap-y-spacing-spacing0',
  'spacing-spacing1': 'gap-y-spacing-spacing1',
  'spacing-spacing2': 'gap-y-spacing-spacing2',
  'spacing-spacing3': 'gap-y-spacing-spacing3',
  'spacing-spacing4': 'gap-y-spacing-spacing4',
  'spacing-spacing5': 'gap-y-spacing-spacing5',
  'spacing-spacing6': 'gap-y-spacing-spacing6',
  'spacing-spacing7': 'gap-y-spacing-spacing7',
  'spacing-spacing8': 'gap-y-spacing-spacing8',
  'spacing-spacing9': 'gap-y-spacing-spacing9',
  'spacing-spacing10': 'gap-y-spacing-spacing10',
  'spacing-spacing11': 'gap-y-spacing-spacing11',
  'spacing-spacing12': 'gap-y-spacing-spacing12',
};
